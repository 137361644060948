import { getShipmentApprovalStatus } from 'api/shippingV3.api';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { setApprovedStatusData } from 'store/slices/shippingDocumentSliceV3';
import { isDocDeliveredOrAppr } from 'utils/utils';

const ShipmentLayout = () => {
    const dispatch = useAppDispatch()
    const {
        shipping_document_details,
    } = useAppSelector((state) => state.shippingDocumentV3);

    useEffect(() => {
        if(shipping_document_details && !isDocDeliveredOrAppr(shipping_document_details)){
        getShipmentApprovalStatus(shipping_document_details?.shipment_document_id).then((res: any) => dispatch(setApprovedStatusData(res)));
        }
    }, [shipping_document_details]);

    return (
        <Outlet />
    )
}

export default ShipmentLayout