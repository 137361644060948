import React from "react";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import LoginPage from "pages/LoginPage";
import { withLoading } from "hocs/withLoading.hoc";
import RequireAuth from "./RequireAuth";
import MainLayout from "components/layouts/MainLayout/MainLayout";
import ScrollToTop from "hooks/scrollToTop";
import AutoCompleteOff from "hooks/autoCompleteOff";
import { getProfileID } from "services/localStorage.service";
import ShipmentLayout from "components/shipmentDocumentV3/ShipmentLayout";

export const DASHBOARD_PATH = "/";

const AuthLayoutFallback = React.lazy(
  () => import("components/layouts/AuthLayout/AuthLayout")
);
const DashboardPage = React.lazy(() => import("pages/DashboardPage"));
const OrderPage = React.lazy(() => import("pages/OrderPage"));
const AgreementPage = React.lazy(() => import("pages/AgreementPage"));
const AddEditAgreementPage = React.lazy(
  () => import("pages/AgreementAddEditPage")
);

const AgreementItemPage = React.lazy(() => import("pages/AgreementItemPage"));
const AgreementProfessionalPage = React.lazy(() => import("components/agreementProfessionals/AgreementProfessionals"));
const AgreementItemAddEditPage = React.lazy(
  () => import("pages/AgreementItemPageAddEdit")
);

const BulkAgreementItemPage = React.lazy(
  () => import("pages/BulkAgreementItemPage")
);

const BulkAgreementItemPageAddEdit = React.lazy(
  () => import("pages/BulkAgreementItemPageAddEdit")
);

const PlanTemplatesPage = React.lazy(() => import("pages/PlanTemplatesPage"));

const ProfessionalPage = React.lazy(() => import("pages/ProfessionalPage"));
const ProfessionalAddEditPage = React.lazy(
  () => import("pages/ProfessionalAddEditPage")
  );

const GarmentPage = React.lazy(() => import("pages/GarmentPage"));
const GarmentAddEditPage = React.lazy(() => import("pages/GarmentAddEditPage"));
const GarmentSettingsPage = React.lazy(() => import("pages/GarmentSettingsPage"));

const GarmentColorPage = React.lazy(() => import("pages/GarmentColorPage"));
const GarmentColorAddEditPage = React.lazy(
  () => import("pages/GarmentColorAddEditPage")
);

const PriceListItemsPage = React.lazy(() => import("pages/PriceListItemsPage"));
const PriceListItemsAddEditPage = React.lazy(
  () => import("pages/PriceListItemsAddEditPage")
);

// const GarmentColorPage = React.lazy(() => import("pages/GarmentColorPage"));
// const GarmentColorAddEditPage = React.lazy(
//   () => import("pages/GarmentColorAddEditPage")
// );

const IncomingSoilPage = React.lazy(() => import("pages/IncomingSoilPage"));
const IncomingSoilAddEditPage = React.lazy(
  () => import("pages/IncomingSoilAddEditPage")
);
const IncomingSoilPageV2 = React.lazy(() => import("pages/IncomingSoilPageV2"));
const IncomingSoilAddEditPageV2 = React.lazy(
  () => import("pages/IncomingSoilAddEditPageV2")
);

const GarmentTypesPage = React.lazy(() => import("pages/GarmentTypesPage"));
const GarmentTypesAddEditPage = React.lazy(
  () => import("pages/GarmentTypesAddEditPage")
);

const GarmentSizeTypePage = React.lazy(
  () => import("pages/GarmentSizeTypePage")
);
const GarmentSizeTypeAddEditPage = React.lazy(
  () => import("pages/GarmentSizeTypeAddEditPage")
);

const ShipmentDocumentPage = React.lazy(
  () => import("pages/ShipmentDocumentPage")
);

const ShipmentDocumentPageV2 = React.lazy(
  () => import("pages/ShipmentDocumentPageV2")
);
const ShipmentDocumentPageV3 = React.lazy(
  () => import("pages/ShipmentDocumentPageV3")
);
const ShipmentDocumentDetailPage = React.lazy(
  () => import("pages/ShipmentDocumentDetailPage")
);
const ShipmentDocumentDetailPageV2 = React.lazy(
  () => import("pages/ShipmentDocumentDetailPageV2")
);
const ShipmentAllScansPage = React.lazy(
  () => import("components/shipmentDocument/allScans/AllScans")
);
const ShipmentAllScansPageV2 = React.lazy(
  () => import("components/shipmentDocumentV2/allScans/AllScans")
);
const ShipmentAllScansPageV3 = React.lazy(
  () => import("components/shipmentDocumentV3/allScans/AllScans")
);
const ShipmentDocumentRfidPage = React.lazy(
  () => import("components/shipmentDocument/rfidScanDocuments/RFIDScanDocument")
);
const ShipmentDocumentRfidPageV2 = React.lazy(
  () => import("components/shipmentDocumentV2/rfidScanDocuments/RFIDScanDocument")
);
const ShipmentDocumentRfidPageV3 = React.lazy(
  () => import("components/shipmentDocumentV3/rfidScanDocuments/RFIDScanDocument")
);
const ShipmentDocumentBarcodePage = React.lazy(
  () => import("components/shipmentDocument/manualEntry/ManualEntry")
);
const ShipmentDocumentBarcodePageV2 = React.lazy(
  () => import("components/shipmentDocumentV2/manualEntry/ManualEntry")
);
const ShipmentDocumentBarcodePageV3 = React.lazy(
  () => import("components/shipmentDocumentV3/manualEntry/ManualEntry")
);
const ShipmentDocumentBulkOrdersPage = React.lazy(
  () => import("components/shipmentDocument/manualEntry2/ManualEntry2")
);
const ShipmentDocumentBulkOrdersPageV2 = React.lazy(
  () => import("components/shipmentDocumentV2/manualEntry2/ManualEntry2")
);
const ShipmentDocumentBulkOrdersPageV3 = React.lazy(
  () => import("components/shipmentDocumentV3/manualEntry2/ManualEntry2")
);
const ShipmentDocumentWashPage = React.lazy(
  () => import("components/shipmentDocument/wash/WashEntry").then((module) => ({ default: module.WashEntry }))
);
const ShipmentDocumentWashPageV2 = React.lazy(
  () => import("components/shipmentDocumentV2/wash/WashEntry").then((module) => ({ default: module.WashEntry }))
);
const ShipmentDocumentWashPageV3 = React.lazy(
  () => import("components/shipmentDocumentV3/wash/WashEntry").then((module) => ({ default: module.WashEntry }))
);
const ShipmentDocumentExceptionPage = React.lazy(
  () => import("components/shipmentDocument/exceptionList/ExceptionList")
);
const ShipmentDocumentExceptionPageV2 = React.lazy(
  () => import("components/shipmentDocumentV2/exceptionList/ExceptionList")
);
const ShipmentDocumentExceptionPageV3 = React.lazy(
  () => import("components/shipmentDocumentV3/exceptionList/ExceptionList")
);
const ShipmentDocumentMissingItemPage = React.lazy(
  () => import("components/shipmentDocument/missingItems/MissingItems")
);
const ShipmentDocumentMissingItemPageV2 = React.lazy(
  () => import("components/shipmentDocumentV2/missingItems/MissingItems")
);
const ShipmentDocumentMissingItemPageV3 = React.lazy(
  () => import("components/shipmentDocumentV3/missingItems/MissingItems")
);
const ClientPage = React.lazy(() => import("pages/clientPage"));
const ClientAddEditPage = React.lazy(() => import("pages/clientAddEditPage"));

const ClientSitePage = React.lazy(() => import("pages/ClientSitePage"));
const ClientSiteAddEditPage = React.lazy(
  () => import("pages/ClientSiteAddEditPage")
);

const ClientDepartmentPage = React.lazy(
  () => import("pages/ClientDepartmentPage")
);
const ClientDepartmentAddEditPage = React.lazy(
  () => import("pages/ClientDepartmentAddEditPage")
);

const ReportPage = React.lazy(() => import("pages/ReportPage"));
const ReportV2Page = React.lazy(() => import("pages/ReportPageV2"));
const RouteAndWorkSchedulePage = React.lazy(
  () => import("pages/RouteAndWorkSchedulePage")
);
const RoutePage = React.lazy(
  () => import("pages/RoutePage")
);
const AddRoutePage = React.lazy(
  () => import("components/routes/addRoute/AddRoute")
);

const DeAffiliationPage = React.lazy(() => import("pages/DeAffiliationPage"));

const VehiclesPage = React.lazy(() => import("pages/VehiclesPage"));
const VehiclesAddEditPage = React.lazy(
  () => import("pages/VehiclesAddEditPage")
);

const DriversPage = React.lazy(() => import("pages/DriversPage"));
const DriversAddEditPage = React.lazy(() => import("pages/DriversAddEditPage"));
const WareHousePage = React.lazy(() => import("pages/WareHousePage"));
const WareHouseV2Page = React.lazy(() => import("pages/WareHouseV2Page"));

const UserPage = React.lazy(() => import("pages/UserPage"));
const AddEditUserPage = React.lazy(() => import("pages/UserAddEditPage"));
const ClientUserPage = React.lazy(() => import("components/clientUser/ClientUser"));
const AddEditClientUserPage = React.lazy(() => import("components/clientUser/clientUserAddEdit/AddEditUser"));

const ChangeErpUserPasswordPage = React.lazy(
  () => import("pages/ChangeErpUserPasswordPage")
);

const AffiliationsPage = React.lazy(() => import("pages/AffiliationsPage"));

const WorkSchedulesPage = React.lazy(() => import("pages/WorkSchedules"));
const WorkSchedules = withLoading(WorkSchedulesPage);

const Logout = React.lazy(() => import("./Logout"));
const Dashboard = withLoading(DashboardPage);
const Order = withLoading(OrderPage);
const Agreement = withLoading(AgreementPage);
const AddEditAgreement = withLoading(AddEditAgreementPage);
const AgreementItem = withLoading(AgreementItemPage);
const AgreementProfessional = withLoading(AgreementProfessionalPage);
const AgreementItemAddEdit = withLoading(AgreementItemAddEditPage);
const Professional = withLoading(ProfessionalPage);
const PlanTemplates = withLoading(PlanTemplatesPage);
const ProfessionalAddEdit = withLoading(ProfessionalAddEditPage);

const Garment = withLoading(GarmentPage);
const GarmentAddEdit = withLoading(GarmentAddEditPage);
const GarmentSettings = withLoading(GarmentSettingsPage);


const PriceListItems = withLoading(PriceListItemsPage);
const PriceListItemsAddEdit = withLoading(PriceListItemsAddEditPage);

// const GarmentSizeType = withLoading(GarmentSizeTypePage);
// const GarmentSizeTypeAddEdit = withLoading(GarmentSizeTypeAddEditPage);

const IncomingSoil = withLoading(IncomingSoilPage);
const IncomingSoilAddEdit = withLoading(IncomingSoilAddEditPage);

const IncomingSoilV2 = withLoading(IncomingSoilPageV2);
const IncomingSoilAddEditV2 = withLoading(IncomingSoilAddEditPageV2);

const ShipmentDocument = withLoading(ShipmentDocumentPage);
const ShipmentDocumentV2 = withLoading(ShipmentDocumentPageV2);
const ShipmentDocumentV3 = withLoading(ShipmentDocumentPageV3);
const ShipmentDocumentDetail = withLoading(ShipmentDocumentDetailPage);
const ShipmentDocumentDetailV2 = withLoading(ShipmentDocumentDetailPageV2);
const ShipmentDocumentRfid = withLoading(ShipmentDocumentRfidPage);
const ShipmentDocumentRfidV2 = withLoading(ShipmentDocumentRfidPageV2);
const ShipmentDocumentRfidV3 = withLoading(ShipmentDocumentRfidPageV3);
const ShipmentAllScans = withLoading(ShipmentAllScansPage);
const ShipmentAllScansV2 = withLoading(ShipmentAllScansPageV2);
const ShipmentAllScansV3 = withLoading(ShipmentAllScansPageV3);
const ShipmentDocumentBarcode = withLoading(ShipmentDocumentBarcodePage);
const ShipmentDocumentBarcodeV2 = withLoading(ShipmentDocumentBarcodePageV2);
const ShipmentDocumentBarcodeV3 = withLoading(ShipmentDocumentBarcodePageV3);
const ShipmentDocumentBulkOrders = withLoading(ShipmentDocumentBulkOrdersPage);
const ShipmentDocumentBulkOrdersV2 = withLoading(ShipmentDocumentBulkOrdersPageV2);
const ShipmentDocumentBulkOrdersV3 = withLoading(ShipmentDocumentBulkOrdersPageV3);
const ShipmentDocumentWash = withLoading(ShipmentDocumentWashPage);
const ShipmentDocumentWashV2 = withLoading(ShipmentDocumentWashPageV2);
const ShipmentDocumentWashV3 = withLoading(ShipmentDocumentWashPageV3);
const ShipmentDocumentException = withLoading(ShipmentDocumentExceptionPage);
const ShipmentDocumentExceptionV2 = withLoading(ShipmentDocumentExceptionPageV2);
const ShipmentDocumentExceptionV3 = withLoading(ShipmentDocumentExceptionPageV3);
const ShipmentDocumentMissingItem = withLoading(ShipmentDocumentMissingItemPage);
const ShipmentDocumentMissingItemV2 = withLoading(ShipmentDocumentMissingItemPageV2);
const ShipmentDocumentMissingItemV3 = withLoading(ShipmentDocumentMissingItemPageV3);

const BulkAgreementItem = withLoading(BulkAgreementItemPage);
const BulkAgreementItemAddEdit = withLoading(BulkAgreementItemPageAddEdit);

const Client = withLoading(ClientPage);
const ClientAddEdit = withLoading(ClientAddEditPage);

const ClientSite = withLoading(ClientSitePage);
const ClientAddEditSite = withLoading(ClientSiteAddEditPage);

const ClientDepartment = withLoading(ClientDepartmentPage);
const ClientAddEditDepartment = withLoading(ClientDepartmentAddEditPage);


const Vehicles = withLoading(VehiclesPage);
const VehiclesAddEdit = withLoading(VehiclesAddEditPage);

const Drivers = withLoading(DriversPage);
const DriversAddEdit = withLoading(DriversAddEditPage);

const Report = withLoading(ReportPage);
const ReportV2 = withLoading(ReportV2Page);

const RouteAndWorkSchedule = withLoading(RouteAndWorkSchedulePage);
const RouteList = withLoading(RoutePage);
const AddRoute = withLoading(AddRoutePage);
const WareHouse = withLoading(WareHousePage);
const WareHouseV2 = withLoading(WareHouseV2Page);

const DeAffiliation = withLoading(DeAffiliationPage);

const Users = withLoading(UserPage);
const UsersAddEdit = withLoading(AddEditUserPage);
const ClientUsers = withLoading(ClientUserPage);
const ClientUsersAddEdit = withLoading(AddEditClientUserPage);

const ChangeErpUserPassword = withLoading(ChangeErpUserPasswordPage);
const Affiliation = withLoading(AffiliationsPage);

const LogoutFallback = withLoading(Logout);

const isAccount = getProfileID() == 1

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
      <AutoCompleteOff></AutoCompleteOff>
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path={DASHBOARD_PATH} element={protectedLayout}>
          {!isAccount && <Route index element={<Dashboard />} />}
          {!isAccount &&  <Route path="dashboard" element={<Dashboard />} />}
          <Route path="orders" element={<Order />} />
          <Route path="garments">
            <Route path="list" element={<Garment />} />
            <Route path="add" element={<GarmentAddEdit />} />
            <Route path="edit/:id" element={<GarmentAddEdit />} />
            <Route path="settings" element={<GarmentSettings />} />
          </Route>
          <Route path="client">
            <Route path="list" element={<Client />} />
            <Route path="add" element={<ClientAddEdit />} />
            <Route path="edit/:id" element={<ClientAddEdit />} />
            <Route path="professional">
              <Route path="list" element={<Professional />} />
              <Route path="add" element={<ProfessionalAddEdit />} />
              <Route path="edit/:id" element={<ProfessionalAddEdit />} />
            </Route>
            <Route path="pricing-list-items">
              <Route path="list" element={<PriceListItems />} />
              <Route path="add" element={<PriceListItemsAddEdit />} />
              <Route path="edit/:id" element={<PriceListItemsAddEdit />} />
            </Route>
            <Route path="bulk-items">
              <Route path="list" element={<BulkAgreementItem />} />
              <Route
                path="bulk-agreement-item/:agreementId/add"
                element={<BulkAgreementItemAddEdit />}
              />
              <Route
                path="bulk-agreement-item/:agreementId/edit/:id"
                element={<BulkAgreementItemAddEdit />}
              />
            </Route>
            <Route path="plan-templates">
              <Route path="list" element={<PlanTemplates />} />
              {/* <Route path="add" element={<ProfessionalAddEdit />} />
              <Route path="edit/:id" element={<ProfessionalAddEdit />} /> */}
            </Route>
            <Route path="agreement">
              <Route path="list" element={<Agreement />} />
              <Route path="add" element={<AddEditAgreement />} />
              <Route path="new" element={<AddEditAgreement />} />
              <Route path="edit/:id" element={<AddEditAgreement />} />
              <Route
                path="agreement-item/:agreementId"
                element={<AgreementItem />}
              />
              <Route
                path="agreement-item/:agreementId/add"
                element={<AgreementItemAddEdit />}
              />
              <Route
                path="agreement-item/:agreementId/edit/:id"
                element={<AgreementItemAddEdit />}
              />
              <Route
                path="agreement-professionals/:agreementId"
                element={<AgreementProfessional />}
              />
            </Route>
            <Route path="client-site/:clientId">
              <Route path="list" element={<ClientSite />} />
              <Route path="add" element={<ClientAddEditSite />} />
              <Route path="edit/:id" element={<ClientAddEditSite />} />
            </Route>
            <Route path="client-department/:clientId">
              <Route path="list" element={<ClientDepartment />} />
              <Route path="add" element={<ClientAddEditDepartment />} />
              <Route path="edit/:id" element={<ClientAddEditDepartment />} />
            </Route>
          </Route>
          <Route path="shipment-document">
            <Route path="list" element={<ShipmentDocument />} />
            <Route path="details/:id/all-scans" element={<ShipmentAllScans />} />
            <Route path="details/:id/rfid" element={<ShipmentDocumentRfid />} />
            <Route path="details/:id/barcode" element={<ShipmentDocumentBarcode />} />
            <Route path="details/:id/bulk-orders" element={<ShipmentDocumentBulkOrders />} />
            <Route path="details/:id/wash" element={<ShipmentDocumentWash />} />
            <Route path="details/:id/exception" element={<ShipmentDocumentException />} />
            <Route path="details/:id/missing-items" element={<ShipmentDocumentMissingItem />} />
          </Route>
          <Route path="shipment-document-v2">
            <Route path="list" element={<ShipmentDocumentV2 />} />
            <Route path="details/:id/all-scans" element={<ShipmentAllScansV2 />} />
            <Route path="details/:id/rfid" element={<ShipmentDocumentRfidV2 />} />
            <Route path="details/:id/barcode" element={<ShipmentDocumentBarcodeV2 />} />
            <Route path="details/:id/bulk-orders" element={<ShipmentDocumentBulkOrdersV2 />} />
            <Route path="details/:id/wash" element={<ShipmentDocumentWashV2 />} />
            <Route path="details/:id/exception" element={<ShipmentDocumentExceptionV2 />} />
            <Route path="details/:id/missing-items" element={<ShipmentDocumentMissingItemV2 />} />
          </Route>
          <Route path="shipment-document-v3">
            <Route path="list" element={<ShipmentDocumentV3 />} />
            <Route path="details/:id"  element={<ShipmentLayout />}>
              <Route path="all-scans" element={<ShipmentAllScansV3 />} />
              <Route path="rfid" element={<ShipmentDocumentRfidV3 />} />
              <Route path="barcode" element={<ShipmentDocumentBarcodeV3 />} />
              <Route path="bulk-orders" element={<ShipmentDocumentBulkOrdersV3 />} />
              <Route path="wash" element={<ShipmentDocumentWashV3 />} />
              <Route path="exception" element={<ShipmentDocumentExceptionV3 />} />
              <Route path="missing-items" element={<ShipmentDocumentMissingItemV3 />} />
          </Route>
          </Route>
          <Route path="report">
            <Route path="list" element={<Report />} />
          </Route>
          <Route path="report-v2">
            <Route path="list" element={<ReportV2 />} />
          </Route>
          <Route path="incoming-soil">
            <Route path="list" element={<IncomingSoil />} />
            <Route path="add" element={<IncomingSoilAddEdit />} />
          </Route>
          <Route path="incoming-soil-v2">
            <Route path="list" element={<IncomingSoilV2 />} />
            <Route path="add" element={<IncomingSoilAddEditV2 />} />
          </Route>
          <Route path="vehicles">
            <Route path="list" element={<Vehicles />} />
            <Route path="add" element={<VehiclesAddEdit />} />
            <Route path="edit/:id" element={<VehiclesAddEdit />} />
          </Route>
          <Route path="drivers">
            <Route path="list" element={<Drivers />} />
            <Route path="add" element={<DriversAddEdit />} />
            <Route path="edit/:id" element={<DriversAddEdit />} />
          </Route>
          <Route path="users">
            <Route path="list" element={<Users />} />
            <Route path="add" element={<UsersAddEdit />} />
            <Route path="edit/:id" element={<UsersAddEdit />} />
            <Route
              path="change-password/:id"
              element={<ChangeErpUserPassword />}
            />
          </Route>
          <Route path="client-users">
            <Route path="add" element={<ClientUsersAddEdit />} />
            <Route path="edit/:id" element={<ClientUsersAddEdit />} />
          </Route>
          {/* <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
          </Route> */}
          <Route path="de-affiliation" element={<DeAffiliation />} />
          <Route path="work-schedule" element={<WorkSchedules />} />
          <Route
            path="routes-and-work-schedule"
            element={<RouteAndWorkSchedule />}
          />
          <Route path="route">
            <Route path="add" element={<AddRoute />} />
            <Route path="list" element={<RouteList />} />
          </Route>
          <Route path="warehouse" element={<WareHouse />} />
          <Route path="warehouse-v2" element={<WareHouseV2 />} />
          <Route path="affiliation" element={<Affiliation />} />
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
